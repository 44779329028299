<script setup lang="ts">
import { ValidationObserver } from 'vee-validate'
import Vue, { ref, onUnmounted } from 'vue'
import { useRouter } from 'vue-router/composables'
import emitter from '/~/core/emitter'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseMetafield from '/~/components/base/metafield/base-metafield.vue'
import { useAccess } from '/~/composables/access/use-access'
import { useAddresses } from '/~/composables/addresses'
import { useForm } from '/~/composables/base/use-form'
import { useLocalization } from '/~/composables/localization'
import { useProvider } from '/~/composables/provider'
import { useStatementAccounts } from '/~/composables/statements'
import { useUser } from '/~/composables/user'

const router = useRouter()
const { createStatementAccount } = useStatementAccounts()
const { isSymbioneliteProvider } = useProvider()
const { addAddress, removeAddress } = useAddresses()
const { user } = useUser()
const { states, translate } = useLocalization()
const { validationObserverRef } = useForm()
const { withAccessCheck } = useAccess()

const addressMetadataSchema = {
  fields: [
    {
      type: 'object',
      key: 'statementAccounts',
      validation: {
        rules: 'required:true',
      },
    },
    {
      type: 'bool',
      key: 'account_owner_acknowledgement',
      label:
        'I agree that I am the rightful owner of this account and consent to receiving statements within this platform.',
      validation: {
        rules: 'required:true',
      },
      class: '!my-8 !items-start',
    },
  ],
  types: {
    statementAccounts: {
      fields: [
        {
          type: 'string',
          key: 'name',
          label: 'Business name',
          validation: {
            rules: 'required',
          },
          class: '!mb-6',
          entryClass: 'rounded !h-[46px] mt-1',
          inputClass: '!py-2.5 !px-3',
          labelClass: '!leading-normal !p-0',
        },
        {
          type: 'string',
          key: 'number',
          label: isSymbioneliteProvider.value
            ? 'Account number'
            : 'Customer Account No.',
          validation: {
            rules: 'required',
          },
          class: '!mb-6',
          entryClass: 'rounded !h-[46px] mt-1',
          inputClass: '!py-2.5 !px-3 ',
          labelClass: '!leading-normal !p-0',
        },
        {
          type: 'address',
          key: 'statementAccountAddress',
          label: 'Business address',
          class: 'grid grid-cols-2 gap-x-6',
          entryClass: 'rounded !h-[46px] mt-1',
          inputClass: '!py-2.5 !px-3 ',
          labelClass: '!leading-normal !p-0',
          validation: {
            rules: 'required',
          },
        },
      ],
      key: 'statementAccounts',
    },
    statementAccountAddress: {
      fields: [
        {
          type: 'string',
          key: 'address',
          label: 'Address',
          validation: {
            rules: 'required',
          },
          class: 'col-span-2 !mb-6',
          entryClass: 'rounded !h-[46px] mt-1',
          inputClass: '!py-2.5 !px-3 ',
          labelClass: '!leading-normal !p-0',
        },
        {
          type: 'string',
          label: 'Suburb',
          key: 'suburb',
          validation: {
            rules: 'required',
          },
          class: 'col-span-2 !mb-6',
          entryClass: 'rounded !h-[46px] mt-1',
          inputClass: '!py-2.5 !px-3 ',
          labelClass: '!leading-normal !p-0',
        },
        {
          type: 'string',
          key: 'postcode',
          label: translate('address.postcode'),
          validation: {
            rules: 'required|numeric|length:4',
          },
          mask: {
            mask: /^[0-9]\d{0,3}$/,
            lazy: true,
          },
          class: '!mb-8',
          entryClass: 'rounded !h-[46px] mt-1',
          inputClass: '!px-3',
          labelClass: '!leading-normal !p-0',
        },
        {
          type: 'list',
          key: 'state',
          label: 'State',
          validation: {
            rules: 'required',
          },
          placeholder: '-Select-',
          entryClass: 'rounded !h-[46px] mt-1',
          labelClass: '!leading-normal !p-0',
        },
      ],
      key: 'statementAccountAddress',
    },
    state: {
      fields: states.value.map((state) => ({
        type: 'string',
        label: state.label,
        key: state.id,
      })),
      key: 'state',
    },
  },
}

const form = ref({
  statementAccounts: {
    name: '',
    number: '',
    statementAccountAddress: {
      address: '',
      suburb: '',
      state: '',
      postcode: '',
    },
  },
})

const backendErrors = ref({})
const loading = ref(false)
const addressId = ref(null)

function processBackendErrors(errors: any, keyPrefix: string) {
  const result: Record<`${string}.${string}`, any> = {}

  for (const key in errors) {
    result[`${keyPrefix}.${key}`] = errors[key][0] ?? ''
  }

  backendErrors.value = result
}

async function createAddress() {
  const address = form.value.statementAccounts.statementAccountAddress
  const payload = {
    companyAddress: null,
    default: false,
    email: user.value.email,
    firstName: user.value.firstName,
    lastName: user.value.lastName,
    mobile: user.value.mobile,
    postcode: address.postcode,
    state: address.state,
    streetAddress: address.address,
    suburb: address.suburb,
  }

  try {
    const data = await addAddress(payload)

    addressId.value = data?.id
  } catch (error: any) {
    processBackendErrors(
      error.data || error,
      'statementAccounts.statementAccountAddress'
    )
  }
}

async function onAdd() {
  withAccessCheck({
    callback: async () => {
      backendErrors.value = {}
      loading.value = true

      try {
        if (!addressId.value) {
          await createAddress()
        }

        if (!addressId.value) {
          return
        }

        const payload = {
          name: form.value.statementAccounts?.name,
          number: form.value.statementAccounts?.number,
          userAddressId: addressId.value,
        }

        await createStatementAccount(payload)

        router.push({
          name: 'statements',
        })

        Vue.notify({
          text: 'Account added successfully',
          type: 'success',
          duration: 10000,
          data: {
            look: 'light',
          },
        })
      } catch (error: any) {
        processBackendErrors(error.data || error, 'statementAccounts')
        removeAddress({ id: addressId.value })
      } finally {
        loading.value = false
      }
    },
  })
}

emitter.on('metafields:validate', () => {
  validationObserverRef.value?.validate()
})

onUnmounted(() => {
  emitter.off('metafields:validate')
})
</script>

<template>
  <validation-observer
    ref="validationObserverRef"
    v-slot="{ invalid, handleSubmit }"
    tag="form"
    class="flex grow flex-col overflow-hidden sm:py-6"
  >
    <div class="w-full grow overflow-y-auto">
      <div class="mx-auto max-w-[578px] p-5 sm:p-0">
        <base-metafield
          v-model="form"
          :schema="addressMetadataSchema"
          :backend-errors="backendErrors"
        />
        <div class="mx-px">
          <base-button
            :full-width="true"
            :disabled="invalid"
            :loading="loading"
            @click="handleSubmit(onAdd)"
          >
            Add
          </base-button>
        </div>
      </div>
    </div>
  </validation-observer>
</template>
