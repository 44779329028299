<script setup lang="ts">
import { computed, ref, onBeforeMount, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import ui from '/~/core/ui'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseTab from '/~/components/base/tabs/base-tab.vue'
import BaseTabs from '/~/components/base/tabs/base-tabs.vue'
import { usePayees } from '/~/composables/payees'
import { useProvider } from '/~/composables/provider'
import { useStatementAccounts } from '/~/composables/statements'
import BankAccountForm from './bank-account-form.vue'
import BPayForm from './bpay-form.vue'
import SymbionForm from './symbion-form.vue'

const router = useRouter()
const route = useRoute()
const { isStatementAccountsEnabled } = useStatementAccounts()
const {
  bankAccountEnabled,
  bpayEnabled,
  NEW_STATEMENT_ACCOUNT_TYPE,
  BANK_ACCOUNT_TYPE,
  BPAY_TYPE,
} = usePayees()
const { providerClientName } = useProvider()

const currentTab = ref(route.params?.type)

const menu = computed(() =>
  [
    {
      label: providerClientName.value,
      value: NEW_STATEMENT_ACCOUNT_TYPE,
      enabled: typeIsNewAccount.value && isStatementAccountsEnabled.value,
      route: {
        params: {
          type: NEW_STATEMENT_ACCOUNT_TYPE,
        },
      },
      component: SymbionForm,
    },
    {
      label: 'Bank Account',
      value: BANK_ACCOUNT_TYPE,
      enabled: !typeIsNewAccount.value && bankAccountEnabled.value,
      route: {
        params: {
          type: BANK_ACCOUNT_TYPE,
        },
      },
      component: BankAccountForm,
    },
    {
      label: 'BPAY',
      value: BPAY_TYPE,
      enabled: !typeIsNewAccount.value && bpayEnabled.value,
      route: {
        params: {
          type: BPAY_TYPE,
        },
      },
      component: BPayForm,
    },
  ].filter((i) => i.enabled)
)

const type = computed(() => route.params.type)
const typeIsNewAccount = computed(
  () => type.value === NEW_STATEMENT_ACCOUNT_TYPE
)
const entryComponent = computed(() => {
  const currentRoute = menu.value.find(
    (item) => item.route.params.type === type.value
  )

  return currentRoute && currentRoute.component
})
const redirect = computed(() => ({
  name: 'payments-payees',
  params: {
    type: type.value === NEW_STATEMENT_ACCOUNT_TYPE ? 'primary' : 'other',
  },
  ...route.meta?.back,
}))

const headingText = computed(() => {
  if (typeIsNewAccount.value) {
    return 'Account details'
  } else {
    return 'Payee details'
  }
})

onBeforeMount(() => {
  if (!route.params.type) {
    router.replace(menu.value[0].route)
  }
})

function onRedirect() {
  router.push(redirect.value)
}

function setRouteType(type: string) {
  router.replace({
    params: {
      type: type,
    },
  })
}

watch(
  currentTab,
  () => {
    setRouteType(currentTab.value)
  },
  {
    immediate: true,
  }
)
</script>

<template>
  <div class="flex h-full flex-col overflow-hidden">
    <template v-if="!ui.mobile">
      <div class="mr-auto mt-[30px] px-5">
        <base-button
          v-analytics:click="{
            pageGroup: 'Make a payment',
            page: 'Add payee',
            cta: 'Back',
          }"
          look="link"
          @click="onRedirect"
        >
          <base-icon svg="chevron-left" size="md" />
          Back
        </base-button>
      </div>
      <h2
        class="text-md mx-auto mb-4 w-full font-bold text-eonx-neutral-800"
        :class="{
          'max-w-xl': typeIsNewAccount,
          'max-w-lg': !typeIsNewAccount,
        }"
      >
        {{ headingText }}
      </h2>
    </template>

    <base-tabs
      v-if="menu.length > 1"
      v-model="currentTab"
      look="v4"
      class="mx-auto w-full bg-white sm:min-w-80 sm:max-w-lg sm:bg-transparent"
    >
      <base-tab
        v-for="item in menu"
        :key="item.value"
        :name="item.label"
        :value="item.value"
        keep-alive
      />
    </base-tabs>

    <component
      :is="entryComponent"
      v-if="entryComponent"
      @success="onRedirect"
    />
  </div>
</template>
